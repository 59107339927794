const lineMessage = 'https://asia-southeast1-wedding-invitation-dca8f.cloudfunctions.net/lineMessage'
const lineLogin = 'https://api.line.me'
const cloudFunction = 'https://asia-southeast1-wedding-invitation-dca8f.cloudfunctions.net'
const thsms = 'https://thsms.com/api'

export {
  lineMessage,
  lineLogin,
  cloudFunction,
  thsms
}