<template>
  <div v-if="displayHeader" style="padding: 35px 15px 0px 15px;" class="fixed h-screen w-72">
    <img src="../../assets/img/sign/logo.png" class="w-44 mx-auto object-contain object-center">

    <ul class="pt-2 mt-4">
      <li
        v-for="(menu, index) in menus"
        :key="index"
        class="m-0 p-0 list-none transition duration-700 transform hover:translate-x-1"
      >
        <div
          @click="toPage(menu.path)"
          :class="[activeLink(menu.path)]"
          class="font-semibold flex items-center text-16 text-mytheme-grayscale900 my-2 py-3 px-4 cursor-pointer"
        >
          <img :src="activeLink(menu.path) ? menu.iconActive : menu.icon" class="mr-2 w-5">
          {{ menu.text }}
        </div>
      </li>
    </ul>

    <div
      @click="submitLogout"
      class="font-semibold cursor-pointer flex items-center text-16 text-mytheme-grayscale900 my-2 py-3 px-4 absolute bottom-0 transition duration-700 transform hover:translate-x-1"
    >
      <img src="../../assets/img/navbarIcon/original/logout.png" class="mr-2 w-5">
      ออกจากระบบ
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import useLoginLine from '../../hooks/authLogin'

export default {
  setup () {
    const router = useRouter()

    const menus = ref([
      {
        path: '/',
        icon: require('../../assets/img/navbarIcon/original/home.png'),
        iconActive: require('../../assets/img/navbarIcon/active/home.png'),
        text: 'หน้าแรก'
      },
      {
        path: '/signup-form',
        icon: require('../../assets/img/navbarIcon/original/edit.png'),
        iconActive: require('../../assets/img/navbarIcon/active/edit.png'),
        text: 'ตั้งค่าฟาร์มลงทะเบียน'
      },
      {
        path: '/manage-line-message',
        icon: require('../../assets/img/navbarIcon/original/staff.png'),
        iconActive: require('../../assets/img/navbarIcon/active/staff.png'),
        text: 'ปรับแต่งข้อมูลบน LINE'
      },
      {
        path: '/report',
        icon: require('../../assets/img/navbarIcon/original/users.png'),
        iconActive: require('../../assets/img/navbarIcon/active/users.png'),
        text: 'ข้อมูลผู้ลงทะเบียน'
      },
      {
        path:'/send-card',
        icon: require('../../assets/img/navbarIcon/original/upload.png'),
        iconActive: require('../../assets/img/navbarIcon/active/upload.png'),
        text: 'ส่งการ์ดเชิญเข้า LINE'
      },
      {
        path: '/settings',
        icon: require('../../assets/img/navbarIcon/original/settings.png'),
        iconActive: require('../../assets/img/navbarIcon/active/settings.png'),
        text: 'ตั้งค่า'
      }
    ])

    const { displayHeader, activeLink } = useShowNavbarMenu()
    const { submitLogout } = useLoginLine()

    const toPage = (path) => {
      router.push({ path })
    }

    return {
      menus,
      displayHeader,
      activeLink,
      toPage,
      submitLogout
    }
  }
}

const useShowNavbarMenu = () => {
  const route = useRoute()

  const currentPath = computed(() => route.path)
  const displayHeader = computed(() => {
    const path = currentPath.value
    if(path == '/signin') return false
    if(path == '/signup') return false

    return true
  })

  const activeLink = (pathFromMenu) => {
    if(currentPath.value == '/' && pathFromMenu == '/') return 'active'
    if(currentPath.value == '/') return

    const [path, subPath] = currentPath.value.substring(1).split('/')
    pathFromMenu = pathFromMenu.substring(1)
    const result = pathFromMenu.includes(path)

    return result ? 'active' : ''
  }

  return {
    displayHeader,
    activeLink
  }
}
</script>

<style scoped>
.active {
  background-color: #D52C5C;
  border-radius: 8px;
  color: #FFFFFF !important;
}
</style>