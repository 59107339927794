<template>
  <div>
    <loading />
    <toast />


    <div v-if="isLogin" style="display: flex;">
      <navbar-side v-if="loaded" />
      <div
        v-if="loaded"
        :style="paddingTop"
        class="content flex-1 bg-white min-h-screen"
      >
        <router-view />
      </div>
    </div>

    <div v-else>
      <router-view v-if="loaded" />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Loading from './components/Loading.vue'
import Toast from './components/Toast/Toast.vue'
import NavbarSide from './components/Navbar/NavbarSide.vue'

export default {
  components: {
    Loading,
    Toast,
    NavbarSide,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const loaded = computed(() => store.state.appState.loaded)
    const isLogin = computed(() => store.state.auth.isLogin)
    const paddingTop = computed(() => {
      const currentPath = route.path
      return currentPath == 'signin' || currentPath == 'signup' ? 'padding-top: 63px;' : ''
    })

    onBeforeMount(() => {
      init()
      document.title = 'wedday'
    })

    const init = async () => {
      try {
        store.commit('appState/TOGGLE_IS_LOADING')
        await store.dispatch('auth/autoLogin')
      } catch (error) {
        console.error(error)
        store.dispatch('auth/logout')
      } finally {
        store.commit('appState/setState', { key: 'loaded', value: true })
        store.commit('appState/TOGGLE_IS_LOADING')
      }
    }

    return {
      loaded,
      isLogin,
      paddingTop
    }
  }
}
</script>

<style scoped>
.content {
  background-color: #ffffff;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  margin-left: 300px;
}
</style>

