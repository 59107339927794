import storageLineFlexMessage from "../../services/storage/lineFlexMessage"
import { formatFullPathStorage } from '../../utility/function/format'


const upload = (eventId) => {
  const uploadImage = (file, fileName, folderName) => {
    return new Promise(async (resolve, reject) => {
      const { _upload, _getDownloadUrl } = storageLineFlexMessage(eventId)
      const { _delegate } = await _upload(folderName, fileName, file)
      const { metadata, state: statusUpload } = _delegate

      if(statusUpload == 'success') {
        const fullPath = metadata.fullPath
        const hdPath = formatFullPathStorage(fullPath, '_hd')
        await delay(1000 * 7)
        const imageUrl = await _getDownloadUrl(hdPath)
        resolve(imageUrl)
      }
    })
  }

  const uploadImagePrewedding = (file, indexAt) => {
    const fileName = `image${indexAt + 1}.jpg`
    return uploadImage(file, fileName, 'imageVideo')
  }

  const delay = (timeout) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve('')
      }, timeout);
    })
  }

  return {
    uploadImage,
    uploadImagePrewedding
  }
}

export default upload