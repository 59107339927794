const appState = {
  namespaced: true,
  state: {
    eventId: '',
    isLoading: false,
    loaded: false,
    toast: {
      isShow: false,
      message: 'message1',
      icon: 'fas fa-check', // font awesome
      colorIcon: 'text-mytheme-success'
    }
  },
  mutations: {
    setState(state, { key, value }) {
      state[key] = value
    },
    setToast(state, { stateName, val }) {
      state.toast[stateName] = val
    },
    TOGGLE_IS_LOADING (state) {
      state.isLoading = !state.isLoading
    }
  },
  actions: {
    showToast ({ commit }, { message, icon, colorIcon, delay }) {
      commit('setToast', { stateName: 'message', val: message || 'message1' })
      commit('setToast', { stateName: 'icon', val: icon || 'fas fa-check' })
      commit('setToast', { stateName: 'colorIcon', val: colorIcon || 'text-mytheme-success' })
      commit('setToast', { stateName: 'isShow', val: true })

      setTimeout(() => {
        commit('setToast', { stateName: 'isShow', val: false })
      }, delay || 1000)
    }
  },
  getters: {}
}

export default appState