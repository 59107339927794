const CLIENT_ID = '1654864455'
const SECRET_ID = 'be47e65da11fb1d9399038d36b6c3d47'
const liffIdAdminInvite = '1654864455-3G8EbGMd'

// channel id คือ client id
// channel secret คือ secret id

export {
  CLIENT_ID,
  SECRET_ID,
  liffIdAdminInvite
}