import { userRef, firebase } from '../../config/firebaseInstance'

const userService = () => {
  const _set = (docId, payload) => {
    return userRef.doc(docId).set(payload)
  }

  const _add = (payload) => {
    return userRef.add(payload)
  }

  const _deleteDocument = (docId) => {
    return userRef.doc(docId).delete()
  }

  const _deleteField = (docId, key) => {
    return userRef.doc(docId).update({
      [key]: firebase.firestore.FieldValue.delete()
    })
  }

  const _update = (docId, payload) => {
    return userRef.doc(docId).update(payload)
  }

  const _updateField = (docId, key, value) => {
    return userRef.doc(docId).update({ [key]: value })
  }

  const _getByDocId = (docId) => {
    return userRef.doc(docId).get()
  }

  const _getByField = (key, value, condition) => {
    return userRef.where(key, condition, value).get()
  }

  const _updateArray = (docId, key, value) => {
    return userRef.doc(docId).update({
      [key]: firebase.firestore.FieldValue.arrayUnion(value)
    })
  }

  const _deleteArray = (docId, key, value) => {
    return userRef.doc(docId).update({
      [key]: firebase.firestore.FieldValue.arrayRemove(value)
    })
  }

  const ref = (docId) => {
    return userRef.doc(docId)
  }

  return {
    _set,
    _add,
    _deleteDocument,
    _deleteField,
    _update,
    _updateField,
    _getByDocId,
    _getByField,
    _updateArray,
    _deleteArray,
    ref
  }
}

export default userService