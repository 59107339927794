import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

import router from './router'
import store from './store'
import VueGoogleMap from '@fawmi/vue-google-maps'
import Vcalendar from 'v-calendar'

// css
import './assets/css/fontsFace.css'
import './assets/tailwind.css'
import './assets/css/main.css'

app
  .use(store)
  .use(router)
  .use(Vcalendar, { componentPrefix: 'v' })
  .use(VueGoogleMap, {
    load: {
      key: 'AIzaSyBGcpS-1VaVp3Qo0xrtynqUH8lFZlPWqTM',
      libraries: 'places',
      region: 'TH',
      language: 'th'
    }
  })
  .mount('#app')
