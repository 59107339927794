import signupFormService from '../../services/database/signupForm'
import uploadSignupForm from '../../controller/storage/signupForm'
import _ from 'lodash'

const signupModule = {
  namespaced: true,
  state: {
    docId: '',
    groomName: '',
    brideName: '',
    promptpay: {
      active: false,
      namePromptpay: '',
      numberPromptpay: '',
      lineNotifyWish: [
        {
          displayName: '',
          pictureUrl: '',
          status: '',
          userId: '',
          wishBy: '' // groom, bride
        }
      ]
    },
    eventInfo: {
      mode: 'morning', //morning, evening, morningAndEvening
      morning: {
        location: {
          active: false,
          address: '',
          name: '',
          coordinates: null,
          url: ''
        },
        floor: '',
        room: '',
        date: new Date().toISOString(),
        time: '00:00',
        theme: '',
        foods: [],
        remark: ''
      },
      evening: {
        location: {
          active: false,
          name: '',
          address: '',
          coordinates: null,
          url: ''
        },
        floor: '',
        room: '',
        date: new Date().toISOString(),
        time: '00:00',
        theme: '',
        foods: []
      }
    },
    guestGroup:{
      groom: ['ญาติฝั่งเจ้าบ่าว', 'เพื่อนร่วมงาน', 'เพื่อนมหาวิทยาลัย'],
      bride: ['ญาติฝั่งเจ้าสาว', 'เพื่อนร่วมงาน', 'เพื่อนมหาวิทยาลัย']
    },
    welcomePageImages: ['', '', '', ''],
    settings: {
      faceSheet: {
        fullName: '',
        address: '',
        phoneNumber: ''
      }
    }
  },
  mutations: {
    setParentState (state, { data }) {
      state = {
        ...state,
        ...data
      }
    },
    setState(state, { stateName, value }) {
      state[stateName] = value
    },
    setModeEventInfo(state, { val }) {
      state.eventInfo.mode = val
    },
    setGuestGroup (state, { stateName, groups }) {
      state.guestGroup[stateName] = groups
    },
    setPromptpay (state, { stateName, val }) {
      state.promptpay[stateName] = val
    },
    setWelcomePageImages (state, { indexAt, url }) {
      state.welcomePageImages[indexAt] = url
    },
    setLineProfileWish(state, { data }) {
      state.promptpay.lineNotifyWish.push(data)
    },
    setFaceSheet(state, { stateName, val }) {
      state.settings.faceSheet[stateName] = val
    },
    removeLineProfileWish(state, { indexAt }) {
      state.promptpay.lineNotifyWish.splice(indexAt, 1)
    },
    setSignupForm (state, payload) {
      for(const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async init({ dispatch }) {
      const exsist = await dispatch('fetchSignupForm')
      if(!exsist) await dispatch('createSignupForm')
      return ''
    },

    async fetchSignupForm({ commit, rootState }) {
      const { eventId } = rootState.appState
      const doc = await signupFormService()._getByDocId(eventId)
      if(doc.exists) {
        commit('setSignupForm', { docId: doc.id, ...doc.data() })
      }

      return doc.exists
    },

    async createSignupForm({ rootState, commit, state }) {
      const { eventId } = rootState.appState
      const model = state
      delete model.docId
      await signupFormService()._set(eventId, {
        ...model,
        eventId,
      })

      commit('setSignupForm', { docId: eventId, ...model })
    },

    async save({ state }) {
      let cloneState = _.cloneDeep(state)
      delete cloneState.docId
      return signupFormService()._update(state.docId, cloneState)
    },

    async uploadImageWelcomePage({ state, commit, dispatch }, { indexAt, file }) {
      const { docId: eventId } = state
      const imageUrl = await uploadSignupForm(eventId).uploadImageWelcomePage(file, indexAt)
      commit('setWelcomePageImages', { indexAt, url: imageUrl })
      return dispatch('save')
    },

    removeImageWelcomePage({ commit, dispatch }, { indexAt }) {
      commit('setWelcomePageImages', { indexAt, url: '' })
      return dispatch('save')
    },

    async insertLineNotifyWish({ commit, dispatch }, { data }) {
      commit('setLineProfileWish', { data })
      await dispatch('save')
      return null
    },

    removelineNotifyWish({ commit, state }, { indexAt }) {
      const { docId: eventId } = state
      commit('removeLineProfileWish', { indexAt })
      return signupFormService()._update(eventId, {
        ['promptpay.lineNotifyWish']: state.promptpay.lineNotifyWish
      })
    },
  },
  getters: {
    checkSignupFormSetupYet(state) {
      const { groomName, brideName, eventInfo, welcomePageImages } = state
      if(!groomName && !brideName) return false
      if(!checkEventInfoSetupYet(eventInfo)) return false
      if(!checkWelcomePageImagesSetupYet(welcomePageImages)) return false
      return true
    }
  }
}

export default signupModule

const checkEventInfoSetupYet = (eventInfo) => {
  switch (eventInfo.mode) {
    case 'morning': {
      return eventInfo.morning.date && eventInfo.morning.time
    }
    case 'evening': {
      return eventInfo.evening.date && eventInfo.evening.time
    }
    default: {
      let result1 = eventInfo.morning.date && eventInfo.morning.time
      let result2 = eventInfo.evening.date && eventInfo.evening.time

      return result1 && result2
    }
  }
}

const checkWelcomePageImagesSetupYet = (welcomePageImages) => {
  let result = true
  for (let i = 0; i < welcomePageImages.length; i++) {
    if(!welcomePageImages[i]) {
      result = false
      break
    }
  }

  return result
}