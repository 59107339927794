import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, reactive, toRefs } from 'vue'
import { makeOtp } from '../utility/function/makeId'
import thsmsAPIs from '../services/APIs/thsms'

const useLoginLine = () => {
  const store = useStore()
  const router = useRouter()

  let state = reactive({
    phoneNumber: '',
    otp: '',
    otpFromGen: ''
  })

  const validatePhoneNumber = computed(() => {
    if(state.phoneNumber == '') {
      return 'กรุณากรอกหมายเลขโทรศัพท์'
    }

    if(!new RegExp('^[0-9]+$').test(state.phoneNumber)) {
      return 'กรุณากรอกเป็นหมายเลข'
    }

    if(state.phoneNumber.length < 9) {
      return 'กรุณากรอกหมายเลขให้ครบจำนวน 9 - 10 หลัก คะ'
    }

    // if not error return null string
    return ''
  })

  const submitSendOtp = () => {
    state.otpFromGen = makeOtp(state.phoneNumber, 6)
    return thsmsAPIs().sendSms({
      sender: 'NICELOOP',
      msisdn: [state.phoneNumber],
      message: `Wedday รหัส otp ของคุณคือ ${state.otpFromGen}`
    })
  }

  const submitLogin = async () => {
    try {
      store.commit('appState/TOGGLE_IS_LOADING')
      await store.dispatch('auth/login')
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('appState/TOGGLE_IS_LOADING')
    }
  }

  const submitSignup = async () => {
    try {
      store.commit('appState/TOGGLE_IS_LOADING')
      if(state.otp != state.otpFromGen) throw('otp is invalid')

      await store.dispatch('auth/signup', { phoneNumber: state.phoneNumber })
      store.commit('auth/setState', { stateName: 'isLogin', val: true })

      await router.push({ path: '/' })

      store.dispatch('appState/showToast', { message: 'ลงทะเบียนเรียบร้อยแล้ว', delay: 1000 * 4 })
    } catch (error) {
      console.error(error)
      if(error == 'phonenumber exsist') {
        store.dispatch('appState/showToast', {
          message: 'เบอร์โทรศัพท์นี้เคยลงทะเบียนไว้แล้วคะ',
          delay: 1000 * 3,
          icon: 'fas fa-times',
          colorIcon: 'text-mytheme-red'
        })
      }

      if(error == 'otp is invalid') {
        store.dispatch('appState/showToast', {
          message: 'รหัส otp ไม่ถูกต้อง',
          delay: 1000 * 3,
          icon: 'fas fa-times',
          colorIcon: 'text-mytheme-red'
        })
      }
    } finally {
      store.commit('appState/TOGGLE_IS_LOADING')
    }
  }

  const submitLogout = async () => {
    try {
      store.commit('appState/TOGGLE_IS_LOADING')
      await store.dispatch('auth/logout')
    } catch (error) {
      console.error(error)
    } finally {
      store.commit('appState/TOGGLE_IS_LOADING')
    }
  }

  return {
    ...toRefs(state),
    validatePhoneNumber,
    submitSendOtp,
    submitLogin,
    submitSignup,
    submitLogout,
  }
}

export default useLoginLine