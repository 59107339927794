<template>
  <div
    id="toast"
    @click="closeToast"
    class="w-64 px-4 py-1 rounded-xl fixed left-1/2 transform -translate-x-1/2 bg-mytheme-grayscale800 cursor-pointer z-50"
    style="transition: all .7s;"
    :style="toastData.isShow ? 'top: 20px;' : 'top: -50px;'"
  >
    <div class="flex items-center space-x-3">
      <i :class="[toastData.icon, toastData.colorIcon]" class="text-21"></i>
      <p class="text-16 font-normal text-white">{{ toastData.message }}</p>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  setup () {
    const store = useStore()

    const toastData = computed(() => store.state.appState.toast)
    
    const closeToast = () => {
      store.commit('appState/setToast', { stateName: 'isShow', val: false })
    }

    return {
      toastData,
      closeToast
    }
  }
}
</script>

<style>

</style>