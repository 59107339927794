import { storageRef } from '../../config/firebaseInstance'

const storageLineFlexMessage = (rootFolderName) => {
  let ref = storageRef.child(`${rootFolderName}/lineFlexMessage`)

  const _upload = (folderName, fileName, file) => {
    return ref.child(`${folderName}/${fileName}`).put(file)
  }

  const _getDownloadUrl = (fullPath) => {
    return storageRef.child(fullPath).getDownloadURL()
  }

  return {
    _upload,
    _getDownloadUrl
  }
}

export default storageLineFlexMessage