import { guestRef } from '../../config/firebaseInstance'

const guestService = () => {
  const _set = (docId, payload) => {
    return guestRef.doc(docId).set(payload)
  }

  const _add = (payload) => {
    return guestRef.add(payload)
  }

  const _deleteDocument = (docId) => {
    return guestRef.doc(docId).delete()
  }

  const _deleteField = (docId, key) => {
    return guestRef.doc(docId).update({
      [key]: firebase.firestore.FieldValue.delete()
    })
  }

  const _update = (docId, payload) => {
    return guestRef.doc(docId).update(payload)
  }

  const _updateField = (docId, key, value) => {
    return guestRef.doc(docId).update({ [key]: value })
  }

  const _getByDocId = (docId) => {
    return guestRef.doc(docId).get()
  }

  const _updateArray = (docId, key, value) => {
    return guestRef.doc(docId).update({
      [key]: firebase.firestore.FieldValue.arrayUnion(value)
    })
  }

  const _deleteArray = (docId, key, value) => {
    return guestRef.doc(docId).update({
      [key]: firebase.firestore.FieldValue.arrayRemove(value)
    })
  }

  return {
    _set,
    _add,
    _deleteDocument,
    _deleteField,
    _update,
    _updateField,
    _getByDocId,
    _updateArray,
    _deleteArray,
    guestRef
  }
}

export default guestService

