import userService from '../../services/database/user'
import _ from 'lodash'
import { makeId } from '../../utility/function/makeId'

const userModule = {
  namespaced: true,
  state: {
    docId: '',
    events: [],
    testers: [],
    phoneNumber: ''
  },
  mutations: {
    setState(state, { key, value }) {
      state[key] = value
    },
    REMOVE_TESTER (state, indexAt) {
      state.testers.splice(indexAt, 1)
    },
    MUTATE_CHECKBOX_TESTER (state, { indexAt, checked }) {
      state.testers[indexAt].checkbox = checked
    } 
  },
  actions: {
    async init({ dispatch }) {
      await dispatch('getUser')
      dispatch('listeningUser')
      return ''
    },

    async getUser({ commit, rootState }) {
      const { userId } = rootState.auth
      const doc = await userService()._getByDocId(userId)
      if (doc.exists) {
        const { events, testers, phoneNumber } = doc.data()
        commit('setState', { key: 'docId', value: doc.id })
        commit('setState', { key: 'events', value: events })
        commit('setState', { key: 'testers', value: testers || [] })
        commit('setState', { key: 'phoneNumber', value: phoneNumber })
        commit('appState/setState', { key: 'eventId', value: events[0] }, { root: true })
      }

      return null
    },

    listeningUser({ commit, rootState }) {
      const { userId } = rootState.auth
      userService().ref(userId).onSnapshot(doc => {
        console.log('listening user', doc.data())
        const { events, testers } = doc.data()
        commit('setState', { key: 'events', value: events })
        commit('setState', { key: 'testers', value: testers || [] })
      })
    },

    removeTesterTestMessage({ state, rootState, commit }, indexAt) {
      commit('REMOVE_TESTER', indexAt)
      const { testers } = state
      const { userId } = rootState.auth
      return userService()._update(userId, {
        testers
      })
    }
  },
  getters: {}
}

export default userModule