import { useRouter, useRoute } from 'vue-router'
import { CLIENT_ID, SECRET_ID, liffIdAdminInvite } from '../../config/lineChannel'
import userService from '../../services/database/user'
import lineLoginAPIs from '../../services/APIs/lineLogin'
import liff from '@line/liff'
import { genHexDecimal } from '../../utility/function/makeId'

const auth = {
  namespaced: true,
  state: {
    // line
    userId: '',
    displayName: '',
    pictureUrl: '',
    statusMessage: '',
    isLogin: false
  },
  mutations: {
    setState(state, { stateName, val }) {
      state[stateName] = val
    },
    setLineProfile (state, { lineProfile }) {
      const { userId, displayName, pictureUrl, statusMessage } = lineProfile
      state.userId = userId || ''
      state.displayName = displayName || ''
      state.pictureUrl = pictureUrl || ''
      state.statusMessage = statusMessage || ''
    }
  },
  actions: {
    async fetchAllData({ dispatch }) {
      await dispatch('user/init', null, { root: true }),
      await dispatch('signupForm/init', null, { root: true }),
      await dispatch('lineFlexMessage/init', null, { root: true }),
      await dispatch('guest/init', null, { root: true })
      return null
    },

    async loginWithPhoneNumber ({ commit, dispatch }, { phoneNumber }) {
      const snapshot = await userService()._getByField('phoneNumber', phoneNumber, '==')
      let tmpArr = []
      snapshot.forEach(doc => {
        tmpArr.push({
          docId: doc.id,
          ...doc.data()
        })
      })

      commit('setLineProfile', { lineProfile: tmpArr[0].lineProfile })
      await dispatch('fetchAllData')
      return null
    },

    // line login
    async login({ commit }) {
      const redirectUrl = window.location.origin + window.location.pathname
      const urlEncode = encodeURIComponent(redirectUrl)

      if (window.location.href.includes('localhost')) {
        window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${urlEncode}&state=null&scope=profile%20openid`
      } else {
        await liff.ready
        liff.login()
        return null
      }
    },
    
    async signup ({ state, dispatch }, { phoneNumber }) {
      if(await checkExsistPhoneNumber(phoneNumber)) throw 'phonenumber exsist'
      const { userId, displayName, pictureUrl, statusMessage } = state
      const events = [genHexDecimal(8)]

      await userService()._set(userId, {
        events,
        phoneNumber,
        lineProfile: { userId, displayName, pictureUrl, statusMessage }
      })
      await dispatch('fetchAllData')
      return null
    },

    async logout () {
      if (window.location.href.includes('localhost')) {
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('refresh_token')
        window.location.reload()
      } else {
        liff.logout()
        await delay(1000)
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('refresh_token')
        window.location.reload()
      }
    },

    async autoLogin ({ commit, dispatch }) {
      const router = useRouter()
      const route = useRoute()
      await liff.init({ liffId: liffIdAdminInvite })
      const isLocalhost = window.location.href.includes('localhost')
      const accessToken = window.localStorage.getItem('access_token')
      await delay(500)

      // login with query phoneNumber
      if(route.query.phoneNumber) {
        await dispatch('loginWithPhoneNumber', { phoneNumber: route.query.phoneNumber })
        await router.push({ path: '/' })
        commit('setState', { stateName: 'isLogin', val: true })
        return
      }

      // localhost login
      if((isLocalhost && route.query.code) || accessToken) {
        const code = route.query.code
        const redirectUrl = window.location.origin + window.location.pathname
        let dataResponse = null

        if(!accessToken) {
          const { data: dataLineToken } = await lineLoginAPIs().getExternalLineToken({
            code,
            redirectUrl: redirectUrl,
            clientId: CLIENT_ID,
            clientSecret: SECRET_ID
          })
          dataResponse = dataLineToken
          window.localStorage.setItem('access_token', dataResponse.access_token)
          window.localStorage.setItem('refresh_token', dataResponse.refresh_token)
        } else {
          const { data: dataRefreshToken } = await lineLoginAPIs().refreshAccessToken({
            refreshToken: window.localStorage.getItem('refresh_token'),
            clientId: CLIENT_ID,
            clientSecret: SECRET_ID
          })
          dataResponse = dataRefreshToken
          window.localStorage.setItem('access_token', dataResponse.access_token)
          window.localStorage.setItem('refresh_token', dataResponse.refresh_token)
        }

        const { data: lineProfile } = await lineLoginAPIs().getExternalProfile(dataResponse.access_token)
        commit('setLineProfile', { lineProfile })

        if(await checkExsistUser(lineProfile.userId)) {
          await dispatch('fetchAllData')
          await router.push({ path: '/' })
          commit('setState', { stateName: 'isLogin', val: true })
        } else {
          router.push({ path: '/signup' })
        }

        return null
      }

      // host login
      if(!isLocalhost) {
        await liff.ready
        if(!liff.isLoggedIn()) {
          router.push({ path: '/signup' })
        } else {
          const lineProfile = await liff.getProfile()
          commit('setLineProfile', { lineProfile })

          if(await checkExsistUser(lineProfile.userId)) {
            await dispatch('fetchAllData')
            await router.push({ path: '/' })
            commit('setState', { stateName: 'isLogin', val: true })
          } else {
            await router.push({ path: '/signup' })
          }

          return null
        }
      }

      await router.push({ path: '/signin' })
    }
  },
  getters: {}
}

export default auth

const checkExsistPhoneNumber = async (phoneNumber) => {
  const snapshot = await userService()._getByField('phoneNumber', phoneNumber.toString(), '==')
  let tmpArr = []
  snapshot.forEach(doc => {
    tmpArr.push(doc.id)
  })

  return tmpArr.length > 0 ? true : false 
}

const checkExsistUser = async (userId) => {
  await delay(1000)
  const doc = await userService()._getByDocId(userId)
  return doc.exists
}

const delay = (timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('')
    }, timeout);
  })
}