import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDdbsVpyYUaJtSleMWLk1XfaTNk4xvYDZg",
  authDomain: "wedding-invitation-dca8f.firebaseapp.com",
  projectId: "wedding-invitation-dca8f",
  storageBucket: "wedding-invitation-dca8f.appspot.com",
  messagingSenderId: "934780550555",
  appId: "1:934780550555:web:8aaf9910e017aa329ef53e"
}
firebase.initializeApp(firebaseConfig)

// instance
const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const authRef = firebase.auth()

// auth
const provider = new firebase.auth.GoogleAuthProvider()
provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
authRef.settings.appVerificationDisabledForTesting = false
authRef.languageCode = 'th'

// firestore
const signupFormRef = db.collection('signupForm')
const userRef = db.collection('users')
const lineFlexMessage = db.collection('lineFlexMessage')
const guestRef = db.collection('guests')

// storage
const storageSignUpRef = storageRef.child('signupForm')

export {
  firebase,
  db,
  authRef,
  provider,
  storageRef,
  signupFormRef,
  userRef,
  lineFlexMessage,
  guestRef,
  storageSignUpRef
}