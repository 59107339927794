import axios from 'axios'
import { lineLogin } from './enpoints'

const lineLoginAPIs = () => {
  const enpoint = lineLogin

  const getExternalLineToken = ({ code, redirectUrl, clientId, clientSecret }) => {
    const fullEnpoint = `${enpoint}/oauth2/v2.1/token`

    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('code', code)
    params.append('redirect_uri', redirectUrl)
    params.append('client_id', clientId)
    params.append('client_secret', clientSecret)

    return axios.post(fullEnpoint, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  const getExternalProfile = (accessToken) => {
    const fullEnpoint = `${enpoint}/v2/profile`
    return axios.get(fullEnpoint, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
  }

  const refreshAccessToken = ({ refreshToken, clientId, clientSecret }) => {
    const fullEnpoint = `${enpoint}/oauth2/v2.1/token`

    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('refresh_token', refreshToken)
    params.append('client_id', clientId)
    params.append('client_secret', clientSecret)

    return axios.post(fullEnpoint, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  return {
    getExternalLineToken,
    getExternalProfile,
    refreshAccessToken
  }
}

export default lineLoginAPIs