import moment from 'moment'
import lineFlexMessageService from '../../services/database/lineFlexMessage'
import uploadLineFlexMessage from '../../controller/storage/lineFlexMessage'
import _ from 'lodash'

const lineFlexMessageModule = {
  namespaced: true,
  state: {
    docId: '',
    messageInvite: '',
    videoInvite: {
      imageUrl: '',
      videoUrl: '',
    },
    videoSurprise: {
      imageUrl: '',
      videoUrl: ''
    },
    frontImageCard: '',
    backImageCard: '',
    imageCover: '',
    useWelcomeImage: true,
    preweddingImages: ['', '', '', '']
  },
  mutations: {
    setState(state, { stateName, val }) {
      state[stateName] = val
    },
    setPreweddingImages (state, { indexAt, url }) {
      state.preweddingImages[indexAt] = url
    },
    mutateLineLFexMessage(state, { key, value }) {
      state.lineFlexMessage[key] = value
    },
    MUTATE_INVITATION_CARD(state, { key, value }) {
      state.invitationCard[key] = value
    },
    setFlexLineMessage (state, payload) {
      const {
        docId,
        invitationCard,
        videoInvite,
        videoSurprise,
        frontImageCard,
        backImageCard,
        useWelcomeImage,
        preweddingImages,
        messageInvite,
        imageCover
      } = payload
      state.docId = docId
      state.invitationCard = invitationCard || {}
      state.videoInvite = videoInvite || {}
      state.videoSurprise = videoSurprise || {}
      state.frontImageCard = frontImageCard || ''
      state.backImageCard = backImageCard || ''
      state.useWelcomeImage = useWelcomeImage || false
      state.preweddingImages = preweddingImages || []
      state.messageInvite = messageInvite || ''
      state.imageCover = imageCover || ''
    }
  },
  actions: {
    async init({ dispatch }) {
      const exsist = await dispatch('fetchFlexLineMessage')
      if(!exsist) await dispatch('create')
      return null
    },
    async fetchFlexLineMessage({ rootState, commit }) {
      const { eventId } = rootState.appState

      const doc = await lineFlexMessageService()._getByDocId(eventId)
      if(doc.exists) {
        commit('setFlexLineMessage', { docId: doc.id, ...doc.data() })
      }

      return doc.exists
    },

    async create({ state, rootState, commit }) {
      const cloneState = _.cloneDeep(state)
      delete cloneState.docId
      const { eventId } = rootState.appState
      await lineFlexMessageService()._set(eventId, {
        ...cloneState,
        eventId
      })

      commit('setFlexLineMessage', { docId: eventId, ...cloneState })
      return null
    },

    async uploadImageAtVideoFlexMessage ({ commit, state }, { stateName, file, fileName }) {
      const { docId } = state
      const val = state[stateName]
      const url = await uploadLineFlexMessage(docId).uploadImage(file, fileName, 'imageVideo')
      const data = {
        ...val,
        imageUrl: url
      }
      await lineFlexMessageService()._update(docId, {
        [stateName]: data
      })
      commit('setState', { stateName, val: data })
      return ''
    },

    async updateVideoLinkAtVideoFlexMessage ({ commit, state }, { stateName, url }) {
      const { docId } = state
      const val = state[stateName]
      const data = {
        ...val,
        videoUrl: url
      }

      await lineFlexMessageService()._update(docId, {
        [stateName]: data
      })
      commit('setState', { stateName, val: data })

      return ''
    },

    async deleteImageAtVideoFlexMessage ({ commit, state }, { stateName }) {
      const { docId } = state
      const val = state[stateName]
      const data = { ...val, imageUrl: '' }
      await lineFlexMessageService()._update(docId, {
        [stateName]: data
      })
      commit('setState', { stateName, val: data })
    },

    async uploadInviteCard ({ commit, state }, { stateName, file, fileName }) {
      const { docId } = state
      const url = await uploadLineFlexMessage(docId).uploadImage(file, fileName, 'imageVideo')
      commit('setState', { stateName, val: url })
      return lineFlexMessageService()._update(docId, {
        [stateName]: url
      })
    },

    async deleteImageInviteCard ({ commit, state }, { stateName }) {
      const { docId } = state
      commit('setState', { stateName, val: '' })
      return lineFlexMessageService()._update(docId, {
        [stateName]: ''
      })
    },

    updatePreweddingImages ({ commit, state }, images) {
      const { docId } = state
      commit('setState', { stateName: 'preweddingImages', val: images })
      return lineFlexMessageService()._update(docId, {
        preweddingImages: images
      })
    },

    async uploadPreweddingImageByIndex ({ commit, state }, { file, indexAt }) {
      const { docId } = state
      const stateName = 'preweddingImages'
      const data = _.cloneDeep(state[stateName])

      const url = await uploadLineFlexMessage(docId).uploadImagePrewedding(file, indexAt)
      data[indexAt] = url
      commit('setState', { stateName, val: data })

      return lineFlexMessageService()._update(docId, {
        [stateName]: data
      })
    },

    async deleteImagePrewedding({ commit, state }, { indexAt }) {
      const { docId } = state
      const stateName = 'preweddingImages'
      const data = _.cloneDeep(state[stateName])
      data[indexAt] = ''
      commit('setState', { stateName, val: data })

      return lineFlexMessageService()._update(docId, {
        [stateName]: data
      })
    },

    // new use
    save ({ state }) {
      const cloneData = _.cloneDeep(state)
      delete cloneData.docId
      return lineFlexMessageService()._update(state.docId, cloneData)
    },
    
    async uploadAndSaveImageCard ({ state, commit, dispatch }, { stateName, file }) {
      // stateName -> frontImageCard, backImageCard
      const { docId } = state
      const fileName = stateName
      const url = await uploadLineFlexMessage(docId).uploadImage(file, fileName, 'image')
      commit('setState', { stateName, val: url })
      return dispatch('save')
    },

    async uploadAndSaveImageVideo ({ state, commit, dispatch }, { stateName, file }) {
      // stateName -> videoInvite, videoSurprise
      const { docId } = state
      const fileName = stateName
      const url = await uploadLineFlexMessage(docId).uploadImage(file, fileName, 'image')
      const data = { ...state[stateName], imageUrl: url }
      commit('setState', { stateName, val: data })
      return dispatch('save')
    },
    
    async uploadAndSavePrewedding({ state, commit, dispatch }, { indexAt, file }) {
      const { docId: eventId } = state
      const url = await uploadLineFlexMessage(eventId).uploadImagePrewedding(file, indexAt)
      commit('setPreweddingImages', { indexAt, url })
      return dispatch('save')
    },

    async removePreweddingImages({ commit, dispatch }, { indexAt }) {
      commit('setPreweddingImages', { indexAt, url: '' })
      return dispatch('save')
    }
  },
  getters: {
    checkLineFlexMessageSetupYet (state) {
      if(!state.messageInvite) return false
      if(!state.videoInvite.imageUrl && !state.videoInvite.videoUrl) return false
      if(!state.videoSurprise.imageUrl && !state.videoSurprise.videoUrl) return false
      if(!state.frontImageCard && !state.backImageCard && !state.imageCover) return false

      if(!state.useWelcomeImage) {
        if(checkPreweddingSetupYet(state.preweddingImages)) return false
      }

      return true
    }
  }
}

export default lineFlexMessageModule

const checkPreweddingSetupYet = (preweddingImages) => {
  let result = true
  for (let i = 0; i < preweddingImages.length; i++) {
    if(!preweddingImages[i]) {
      result = false
      break
    }
  }

  return result
}