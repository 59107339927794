import guestService from '../../services/database/guests'
import _ from 'lodash'

var unSubscribeListening = null

const guest = {
  namespaced: true,
  state: {
    guests: [
      {
        morning: {
          numberOfGuest: 0,
          attend: false,
        },
        evening: {
          numberOfGuest: 0,
          attend: false
        },
        groupOf: '',
        guestOf: '',
        fullName: '',
        firstName: '',
        lastName: '',
        nameGuest: '',
        tel: '',
        address: '',
        attend: true,
        profileImage: '',
        amountWish: '',
        wish: '',
        wishOpened: false,
        payment: {
          active: false,
          slipImageUrl: '',
          verified: false,
          timestamp: '',
          amount: ''
        },
        lineProfile: {
          displayName: '',
          pictureUrl: '',
          statusMessage: '',
          userId: ''
        },
        createAt: ''
      }
    ]
  },
  mutations: {
    setState(state, { stateName, value }) {
      state[stateName] = value
    },
    setWishOpend(state, { indexAt, val }) {
      state.guests[indexAt].wishOpened = val
    }
  },
  actions: {
    init ({ dispatch }) {
      dispatch('listeningGuests')
    },

    listeningGuests ({ commit, rootState }) {
      const { eventId } = rootState.appState
      unSubscribeListening = guestService()
        .guestRef
        .where('eventId', '==', eventId)
        .onSnapshot(onSnap => {
          let tmpArr = []
          onSnap.forEach(doc => {
            tmpArr.push({ docId: doc.id, ...doc.data() })
          })

          const sort = _.orderBy(tmpArr, 'createAt', 'desc')
          commit('setState', { stateName: 'guests', value: sort })
          console.log('listening guests: ', sort)
        })
    },
    filterGuests ({ commit, rootState, dispatch }, { stateName, val }) {
      unSubscribeListening()
      if(!val) return dispatch('listeningGuests')
      const { eventId } = rootState.appState
      unSubscribeListening = guestService()
        .guestRef
        .where('eventId', '==', eventId)
        .where(stateName, '==', val)
        .onSnapshot(onSnap => {
          let tmpArr = []
          onSnap.forEach(doc => {
            tmpArr.push({ docId: doc.id, ...doc.data() })
          })

          const sort = _.orderBy(tmpArr, 'createAt', 'desc')
          commit('setState', { stateName: 'guests', value: sort })
          console.log('listening guests: ', sort)
        })
    },

    updateWishOpened ({ state, commit }, { guest, val }) {
      const indexAt = state.guests.findIndex(item => item.docId === guest.docId)
      commit('setWishOpend', { indexAt, val })

      return guestService()._update(guest.docId, {
        wishOpened: val
      })
    }
  },
  getters: {
    sumNumberGuests (state) {
      return _.reduce(state.guests, (acc, value) => {
        acc.all += 1

        if(value.attend) {
          if(value.guestOf == 'groom') {
            acc.guestOfGroom += 1
          }

          if(value.guestOf == 'bride') {
            acc.guestOfBride += 1
          }

          if(value.morning) {
            acc.countGuest += value.morning.numberOfGuest || 0
          }

          if(value.evening) {
            acc.countGuest += value.evening.numberOfGuest || 0
          }

          acc.numberAttend += 1
        } else {
          acc.numberNotAttend += 1
        }

        return acc
      }, {
        all: 0,
        countGuest: 0,
        numberAttend: 0,
        numberNotAttend: 0,
        guestOfGroom: 0,
        guestOfBride: 0
      })
    },
    morningGuest (state) {
      return _.filter(state.guests, (item) => {
        if(item.attend) {
          return item.morning && item.morning.attend === true
        }
      }).map(item => { return { ...item, numberOfGuest: item.morning.numberOfGuest } })
    },
    eveningGuest (state) {
      return _.filter(state.guests, (item) => {
        if(item.attend) {
          return item.evening && item.evening.attend === true
        }
      }).map(item => { return { ...item, numberOfGuest: item.evening.numberOfGuest } })
    },
    guestNotAttend (state) {
      return _.filter(state.guests, (item) => {
        return item.attend === false
      })
    },
  }
}

export default guest