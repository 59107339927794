import storageSignupFormService from '../../services/storage/signupForm'
import { formatFullPathStorage } from '../../utility/function/format'

const upload = (eventId) => {
  const uploadImage = (file, fileName, folderName) => {
    return new Promise(async (resolve) => {
      const { _upload, _getDownloadUrl } = storageSignupFormService(eventId)
      const { _delegate } = await _upload(folderName, fileName, file)
      const { metadata, state: statusUpload } = _delegate

      if(statusUpload == 'success') {
        const fullPath = metadata.fullPath
        const hdPath = formatFullPathStorage(fullPath, '_hd')
        setTimeout(async () => {
          const imageUrl = await _getDownloadUrl(hdPath)
          resolve(imageUrl)
        }, 1000 * 7);
      }
    })
  }

  const uploadImageWelcomePage = (file, indexAt) => {
    const fileName = `image${indexAt + 1}.jpg`
    return uploadImage(file, fileName, 'welcomePage')
  }

  const uploadImageThanksPage = (file) => {
    const fileName = 'imageThanksPage.jpg'
    return uploadImage(file, fileName, 'thanksPage')
  }

  return {
    uploadImageWelcomePage,
    uploadImageThanksPage
  }
}

export default upload