import axios from 'axios'
import { thsms } from './enpoints'

const thsmsAPIs = () => {
  const enpoint = thsms

  const sendSms = ({sender, msisdn, message}) => {
    const fullEnpoint = `${enpoint}/send-sms`
    const accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90aHNtcy5jb21cL2FwaS1rZXkiLCJpYXQiOjE2Mzc4MTI0NDYsIm5iZiI6MTYzNzgxMjQ0NiwianRpIjoibHZOaFJyU3FnUnZDcnNqWSIsInN1YiI6MTQ3NywicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.rbSTfE1i2cALknHQcpAzEDG9yUYD8wYnFMI8jdgoXxE'
    return axios({
      headers: { Authorization: `Bearer ${accessToken}` },
      url: fullEnpoint,
      method: 'POST',
      data: {
        sender,
        msisdn,
        message
      }
    })
  }

  return {
    sendSms
  }
}

export default thsmsAPIs