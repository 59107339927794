import { createStore } from 'vuex'
import appState from './modules/appState'
import signupForm from './modules/signupForm'
import user from './modules/user'
import lineFlexMessage from './modules/lineFlexMessage'
import guest from './modules/guest'
import auth from './modules/auth'

const store = createStore({
  modules: {
    appState,
    signupForm,
    user,
    lineFlexMessage,
    guest,
    auth
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {}
})

export default store
