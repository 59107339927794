import { storageRef } from '../../config/firebaseInstance'

const storageSignupService = (rootFolderName) => {
  const ref = storageRef.child(`${rootFolderName}/signupForm`)

  const _uploadThanksPage = (eventId, fileNmae, file) => {
    return storageSignUpRef
            .child(`${eventId}/thanksPage/${fileNmae}`)
            .put(file)
  }

  const _upload = (folderName, fileName, file) => {
    return ref.child(`${folderName}/${fileName}`).put(file)
  }

  const _getDownloadUrl = (fullPath) => {
    return storageRef.child(fullPath).getDownloadURL()
  }

  return {
    _uploadThanksPage,
    _upload,
    _getDownloadUrl
  }
}

export default storageSignupService