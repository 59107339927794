import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index.vue')
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/auth/signin.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/auth/signup.vue')
  },
  {
    path: '/manage-line-message',
    name: 'ManageLineMessage',
    component: () => import('../views/line-message.vue')
  },
  {
    path: '/signup-form',
    name: 'SignupForm',
    component: () => import('../views/signup-form.vue')
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report.vue')
  },
  {
    path: '/report/face-sheet',
    name: 'FaceSheet',
    component: () => import('../views/report/face-sheet.vue')
  },
  {
    path: '/send-card',
    name: 'SendCard',
    component: () => import('../views/send-card.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/settings.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
