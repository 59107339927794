export const formatFullPathStorage = (fullPath, appendName) => {
  const fullPathSplit = fullPath.split('/')
  const fileName = fullPathSplit[fullPathSplit.length - 1]
  const splitFileName = fileName.split('.')
  const name = splitFileName[0]
  const nameFileExtension = splitFileName[1]

  const indexOfFileName = fullPath.indexOf(fileName)
  const path = fullPath.substring(0, indexOfFileName)

  const newFileName = `${name}${appendName}.${nameFileExtension}`

  const newFullPath = path + newFileName
  return newFullPath
}